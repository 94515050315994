// WebsiteCard.tsx
import React, { useState } from 'react';
import '../styles/WebsiteCard.css'; // Import the CSS file

type WebsiteCardProps = {
	title: string;
	description: string;
	url: string;
};

const WebsiteCard: React.FC<WebsiteCardProps> = ({
	title,
	description,
	url,
}) => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<div className='card-container'>
			<a
				className='card-link'
				href={url}
				target='_self'
				rel='noopener noreferrer'
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}>
				<div className={`card ${isHovered ? 'hover' : ''}`}>
					<h3>{title}</h3>
					<p>{description}</p>
				</div>
			</a>
		</div>
	);
};

export default WebsiteCard;
