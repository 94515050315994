import WebsiteCard from './WebsiteCard';

const Home: React.FC = () => {
	return (
		<div style={{ padding: '20px' }}>
			<WebsiteCard
				title='Brainrot'
				description='Simply a dancing Mami GIF.'
				url='https://brainrot.tiro-finale.com'
			/>
			<WebsiteCard
				title="In case I'm gone"
				description="A little farewell I wrote to the people I love, in case I'm gone."
				url='/Farewell'
			/>
		</div>
	);
};

export default Home;
